import * as React from "react";
import "./styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import LandingPage from "./landing";

library.add(fab);
library.add(far);

const IndexPage = () => <LandingPage />;

export default IndexPage;
